import React, { useEffect } from 'react';
import { Panel, Formpanel, Container, ComboBoxField, Button, CheckBoxField, Loadmask, Grid, TimeField, NumberField, Column, FieldSet, TextField, TextAreaField } from '@sencha/ext-react-modern';
import { useState, useContext } from "react";
import { VersioneRtoContext } from '../components/VersioneRtoContext';
import manovreModel from '../models/ManovreLocalitaModel.js';
import localitaModel from '../models/LocalitaModel.js';
import localitaPercorsoModel from '../models/LocalitaPercorsoRTOModel.js';
import { useUser } from '../components/UserContext';
import Calendario from "../components/Calendario/Calendario/Calendario.js";
import CalendarioSintetico from "../components/Calendario/CalendarioSintetico/CalendarioSintetico.js";
import { parseDate } from "../components/Calendario/Calendario/Funzioni.js";
import periodicitaModel from '../models/PeriodicitaIIServiziModel.js';
const Ext = window['Ext'];
Ext.require([
    'Ext.grid.filters.*',
    'Ext.grid.rowedit.Plugin',
    // 'Ext.grid.plugin.CellEditing',
    'Ext.grid.plugin.Exporter',
    'Ext.grid.plugin.RowExpander'
]);


let arrayLocalitaDisponibili = [];
let arrayOperazioni = [];
let arrayGestori = [];
let arrayAree = [];
let arrayAreeApp = [];
let arrayBinari = [];
let arrayFasci = [];
let arrayBinariOrigine = [];
let arrayFasciOrigine = [];
let arrayBinariDestino = [];
let arrayFasciDestino = [];
let arraySelezione = [];
let idManovra = null;
let titolo = "";
let manovraSelezionata = null;
let localitaSelezionata = null;
var rangeDateStart = null;
var rangeDateEnd = null;

const bodyStyleColor = "background:#f8f8f8;";

const storeLocalita = Ext.create('Ext.data.Store', {
    model: localitaModel,
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});

const storeManovreImpianto = Ext.create('Ext.data.Store', {
    model: manovreModel,
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});

const storePeriodicitaIIManovre = Ext.create('Ext.data.Store', {
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});

const storePercorso = Ext.create('Ext.data.Store', {
    model: localitaPercorsoModel,
    proxy: {
        type: 'localstorage',
    },
    autoload: false
});


function RtoIIManovre(props) {
    function aggiornaControllo(stato) {
        props.aggiornaStateControllo();
    }
    const contextVersioneRto = useContext(VersioneRtoContext);
    const rtoSchede = contextVersioneRto.storeRtoSchede;
    const { user, switchUser } = useUser();
    const [stateLoadMask, setStateLoadMask] = useState({ hidden: true, message: "Attendere salvataggio in corso...", ricarica: true });
    const [state, setState] = useState({ selectedRow: null, showScheda: false, edit: false, modifica: false })
    const [calendars, setCalendars] = useState(null);
    const [isCalendarioVisible, setIsCalendarioVisible] = useState(false);
    rangeDateStart = new Date(contextVersioneRto.storePeriodiFerroviari.findRecord("ideAnnFro", rtoSchede.data.items[0].data.rto.ideAnnFro, 0, false, false, true).data.datIniVal);
    rangeDateEnd = new Date(contextVersioneRto.storePeriodiFerroviari.findRecord("ideAnnFro", rtoSchede.data.items[0].data.rto.ideAnnFro, 0, false, false, true).data.datFinVal);
    function aggiornaStateCheck(ckPeriodi) {
        props.aggiornaStateModifica(true);
        props.aggiornaStatePeriodII(ckPeriodi);
    }

    let row = state.selectedRow !== null ? state.selectedRow.data : null;
    if (row != null) {
        arraySelezione = [];
        var app = row.operazioniManovraImpianto.filter(x => x.flG_ATV === 1);
        for (var i = 0; i < app.length; i++) {
            arraySelezione.push(app[i].idE_OPE_MVN);
        }
    }
    //-----------------------
    const [isAutoprod, setIsAutoprod] = useState(row != null ? ((row.auT_PRD === 1) ? true : false) : true);
    /*    arrayOperazioni = [];*/
    //for (let i = 0; i < opeApp.length; i++) {
    //    let app = { text: opeApp[i].deS_OPE_MVN, value: opeApp[i].idE_OPE_MVN };
    //    arrayOperazioni.push(app);
    //}

    let gestApp = props.gestori;
    arrayGestori = [];
    for (let i = 0; i < gestApp.length; i++) {
        let app = { text: gestApp[i].noM_GSR_MVN, value: gestApp[i].idE_GSR_MVN };
        arrayGestori.push(app);
    }

    const caricaPercorso = () => {
        Ext.Ajax.request({
            url: 'Rto/LeggiPercorsoRTO',
            method: 'GET',
            params: {
                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
            },
            callback: function (records, success, operation) {
                var returnObj = Ext.decode(operation.responseText);
                for (let i = 0; i < returnObj.length; i++) {
                    returnObj[i].flgCfn = returnObj[i].flgCfn;
                    returnObj[i].flgServizio = returnObj[i].flgFlg.indexOf("S") !== -1;
                    returnObj[i].flgManovra = returnObj[i].flgFlg.indexOf("M") !== -1;
                    returnObj[i].flgLegame = returnObj[i].flgFlg.indexOf("L") !== -1;
                }
                storePercorso.loadData(returnObj);
                Ext.ComponentQuery.query('#gridPercorso')[0].setStore(storePercorso);
            }
        });
    }

    const handleSubmit = (btn) => {

        //qui verifico solo i campi obbligatori poi chiamo il metodo sul controllor che verifica la qualità dei dati
        //caso gestore manovra
        setStateLoadMask({ hidden: false, message: "Attendere salvataggio in corso..." });
        if (Ext.ComponentQuery.query('#tipoGestione')[0]._value === 0 && (Ext.ComponentQuery.query('#cmbRtoIILocalita')[0]._value === null || Ext.ComponentQuery.query('#cmbRtoIILocalita')[0]._value === "" ||
            Ext.ComponentQuery.query('#gestore')[0]._value === null || Ext.ComponentQuery.query('#gestore')[0]._value === "" ||
            Ext.ComponentQuery.query('#operazione')[0]._value === null || Ext.ComponentQuery.query('#operazione')[0]._value === "" ||
            Ext.ComponentQuery.query('#mad')[0]._value === null || Ext.ComponentQuery.query('#mad')[0]._value === "" ||
            Ext.ComponentQuery.query('#areaOrigine')[0]._value === null || Ext.ComponentQuery.query('#areaOrigine')[0]._value === "" ||
            Ext.ComponentQuery.query('#areaDestino')[0]._value === null || Ext.ComponentQuery.query('#areaDestino')[0]._value === "" ||
            Ext.ComponentQuery.query('#consegnaMat')[0]._value === null || Ext.ComponentQuery.query('#consegnaMat')[0]._value === "")) {
            Ext.toast({ message: 'Verificare i campi contrassegnati come obbligatori', timeout: 4000 })
            setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
        }
        else if (Ext.ComponentQuery.query('#tipoGestione')[0]._value === 1 && (Ext.ComponentQuery.query('#cmbRtoIILocalita')[0]._value === null || Ext.ComponentQuery.query('#cmbRtoIILocalita')[0]._value === "" ||
            Ext.ComponentQuery.query('#areaOrigine')[0]._value === null || Ext.ComponentQuery.query('#areaOrigine')[0]._value === "" ||
            Ext.ComponentQuery.query('#areaDestino')[0]._value === null || Ext.ComponentQuery.query('#areaDestino')[0]._value === "" || Ext.ComponentQuery.query('#inizio')[0]._value === "" ||
            Ext.ComponentQuery.query('#inizio')[0]._value === null || Ext.ComponentQuery.query('#fine')[0]._value === "" || Ext.ComponentQuery.query('#fine')[0]._value === null)) {
            Ext.toast({ message: 'Verificare i campi contrassegnati come obbligatori', timeout: 4000 })
            setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
        }
        else {
            var oraStart = (Ext.ComponentQuery.query('#inizio')[0]._value != null && Ext.ComponentQuery.query('#inizio')[0]._value != "") ? (('0' + Ext.ComponentQuery.query('#inizio')[0]._value.getHours()).slice(-2) + ':' + ('0' + Ext.ComponentQuery.query('#inizio')[0]._value.getMinutes()).slice(-2)) : "";
            var oraEnd = (Ext.ComponentQuery.query('#fine')[0]._value != null && Ext.ComponentQuery.query('#fine')[0]._value != "") ? (('0' + Ext.ComponentQuery.query('#fine')[0]._value.getHours()).slice(-2) + ':' + ('0' + Ext.ComponentQuery.query('#fine')[0]._value.getMinutes()).slice(-2)) : "";
            var oraMad = (Ext.ComponentQuery.query('#mad')[0]._value != null && Ext.ComponentQuery.query('#mad')[0]._value != "") ? (('0' + Ext.ComponentQuery.query('#mad')[0]._value.getHours()).slice(-2) + ':' + ('0' + Ext.ComponentQuery.query('#mad')[0]._value.getMinutes()).slice(-2)) : "";
            var oraConsegna = (Ext.ComponentQuery.query('#consegnaMat')[0]._value != null && Ext.ComponentQuery.query('#consegnaMat')[0]._value != "") ? (('0' + Ext.ComponentQuery.query('#consegnaMat')[0]._value.getHours()).slice(-2) + ':' + ('0' + Ext.ComponentQuery.query('#consegnaMat')[0]._value.getMinutes()).slice(-2)) : "";
            var oraOperazioni = (Ext.ComponentQuery.query('#termineOp')[0]._value != null && Ext.ComponentQuery.query('#termineOp')[0]._value != "") ? (('0' + Ext.ComponentQuery.query('#termineOp')[0]._value.getHours()).slice(-2) + ':' + ('0' + Ext.ComponentQuery.query('#termineOp')[0]._value.getMinutes()).slice(-2)) : "";
            var oraTrasferimento = (Ext.ComponentQuery.query('#trasMater')[0]._value != null && Ext.ComponentQuery.query('#trasMater')[0]._value != "") ? (('0' + Ext.ComponentQuery.query('#trasMater')[0]._value.getHours()).slice(-2) + ':' + ('0' + Ext.ComponentQuery.query('#trasMater')[0]._value.getMinutes()).slice(-2)) : "";
            var oraAggsgO = (Ext.ComponentQuery.query('#aggsga')[0]._value != null && Ext.ComponentQuery.query('#aggsga')[0]._value != "") ? (('0' + Ext.ComponentQuery.query('#aggsga')[0]._value.getHours()).slice(-2) + ':' + ('0' + Ext.ComponentQuery.query('#aggsga')[0]._value.getMinutes()).slice(-2)) : "";
            var oraRimozione = (Ext.ComponentQuery.query('#applicrim')[0]._value != null && Ext.ComponentQuery.query('#applicrim')[0]._value != "") ? (('0' + Ext.ComponentQuery.query('#applicrim')[0]._value.getHours()).slice(-2) + ':' + ('0' + Ext.ComponentQuery.query('#applicrim')[0]._value.getMinutes()).slice(-2)) : "";
            if (state.modifica === false) {
                Ext.Ajax.request({
                    url: 'Rto/InserisciManovreImpegnoImpianto',
                    method: 'POST',
                    timeout: 600000,
                    params: {
                        ideUte: user.ideUte,
                        ideRTO: rtoSchede.data.items[0].data.rto.ideRTO,
                        flagAutoProduzione: Ext.ComponentQuery.query('#tipoGestione')[0]._value,
                        ideGestore: Ext.ComponentQuery.query('#gestore')[0]._value,
                        oraInizio: oraStart,
                        oraFine: oraEnd,
                        offSetInizio: Ext.ComponentQuery.query('#offesetInizio')[0]._value,
                        offSetFine: Ext.ComponentQuery.query('#offesetFine')[0]._value,
                        ideAreaOrigine: Ext.ComponentQuery.query('#areaOrigine')[0]._value,
                        ideFascioOrigine: Ext.ComponentQuery.query('#fascioOrigine')[0]._value,
                        ideBinarioOrigine: Ext.ComponentQuery.query('#binarioOrigine')[0]._value,
                        ideAreaDestinazione: Ext.ComponentQuery.query('#areaDestino')[0]._value,
                        ideFascioDestinazione: Ext.ComponentQuery.query('#fasciodestino')[0]._value,
                        ideBinarioDestinazione: Ext.ComponentQuery.query('#binarioDestino')[0]._value,
                        massa: Ext.ComponentQuery.query('#massa')[0]._value,
                        lunghezza: Ext.ComponentQuery.query('#lunghezza')[0]._value,
                        checkMp: Ext.ComponentQuery.query('#checkMP')[0]._checked === true ? 1 : 0,
                        noteMP: Ext.ComponentQuery.query('#notaMP')[0]._value,
                        checkTES: Ext.ComponentQuery.query('#checkTS')[0]._checked === true ? 1 : 0,
                        checkTEP: Ext.ComponentQuery.query('#checkTP')[0]._checked === true ? 1 : 0,
                        checkTE: Ext.ComponentQuery.query('#checkTE')[0]._checked === true ? 1 : 0,
                        noteTE: Ext.ComponentQuery.query('#noteTE')[0]._value,
                        checkTC: Ext.ComponentQuery.query('#checkTC')[0]._checked === true ? 1 : 0,
                        noteTC: Ext.ComponentQuery.query('#noteTC')[0]._value,
                        note: Ext.ComponentQuery.query('#note')[0]._value,
                        madOra: oraMad,
                        offeSetMad: Ext.ComponentQuery.query('#offsetMad')[0]._value,
                        consegnaOra: oraConsegna,
                        offSetConsegna: Ext.ComponentQuery.query('#offsetConsegna')[0]._value,
                        operazioniOra: oraOperazioni,
                        offSetOpe: Ext.ComponentQuery.query('#offsetermineOp')[0]._value,
                        trasferimentoOra: oraTrasferimento,
                        offSettrasferimento: Ext.ComponentQuery.query('#offsetTrasMat')[0]._value,
                        aggsgOra: oraAggsgO,
                        offSetAggSga: Ext.ComponentQuery.query('#offsetAggSga')[0]._value,
                        rimozioneOra: oraRimozione,
                        offsetRim: Ext.ComponentQuery.query('#offsetApplicrim')[0]._value,
                        operazioniManovra: Ext.ComponentQuery.query('#operazione')[0]._value,
                        ideLoc: arrayLocalitaDisponibili.find(x => x.value === Ext.ComponentQuery.query('#cmbRtoIILocalita')[0]._value).ideLoc,
                        ideTipTra: rtoSchede.data.items[0].data.rto.ideTipTra,
                        prgPrc: Ext.ComponentQuery.query('#cmbRtoIILocalita')[0]._value
                    },
                    callback: function (records, success, operation) {
                        if (success) {
                            var returnObj = Ext.decode(operation.responseText);
                            //modifica gestione errore
                            if (returnObj.resp === "KO") {
                                setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                                Ext.Msg.alert('Inserimento manovra fallito', returnObj.msgErr);
                                Ext.ComponentQuery.query('#areaOrigine')[0].setOptions(arrayAree.filter(x => x.ideLoc === arrayLocalitaDisponibili.find(x => x.value === Ext.ComponentQuery.query('#cmbRtoIILocalita')[0]._value).ideLoc));
                                Ext.ComponentQuery.query('#areaDestino')[0].setOptions(arrayAree.filter(x => x.ideLoc === arrayLocalitaDisponibili.find(x => x.value === Ext.ComponentQuery.query('#cmbRtoIILocalita')[0]._value).ideLoc));
                            }
                            else {
                                props.aggiornaStateModifica(true);
                                Ext.toast({ message: 'Manovra creata', timeout: 4000 });
                                caricaPercorso();
                                aggiornaControllo(true);
                                chiudiSchedaAggiorna();
                            }
                        }
                        else {
                            setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                            Ext.Msg.alert('Inserimento manovra fallito', 'Inserimento manovra fallito');
                            Ext.ComponentQuery.query('#areaOrigine')[0].setOptions(arrayAree.filter(x => x.ideLoc === arrayLocalitaDisponibili.find(x => x.value === Ext.ComponentQuery.query('#cmbRtoIILocalita')[0]._value).ideLoc));
                            Ext.ComponentQuery.query('#areaDestino')[0].setOptions(arrayAree.filter(x => x.ideLoc === arrayLocalitaDisponibili.find(x => x.value === Ext.ComponentQuery.query('#cmbRtoIILocalita')[0]._value).ideLoc));
                        }
                    }
                })
            }
            else {
                Ext.Ajax.request({
                    url: 'Rto/ModificaManovreImpegnoImpianto',
                    method: 'POST',
                    timeout: 600000,
                    params: {
                        ideUte: user.ideUte,
                        ideRTO: rtoSchede.data.items[0].data.rto.ideRTO,
                        flagAutoProduzione: Ext.ComponentQuery.query('#tipoGestione')[0]._value,
                        ideGestore: Ext.ComponentQuery.query('#gestore')[0]._value,
                        oraInizio: oraStart,
                        oraFine: oraEnd,
                        offSetInizio: Ext.ComponentQuery.query('#offesetInizio')[0]._value,
                        offSetFine: Ext.ComponentQuery.query('#offesetFine')[0]._value,
                        ideAreaOrigine: Ext.ComponentQuery.query('#areaOrigine')[0]._value,
                        ideFascioOrigine: Ext.ComponentQuery.query('#fascioOrigine')[0]._value,
                        ideBinarioOrigine: Ext.ComponentQuery.query('#binarioOrigine')[0]._value,
                        ideAreaDestinazione: Ext.ComponentQuery.query('#areaDestino')[0]._value,
                        ideFascioDestinazione: Ext.ComponentQuery.query('#fasciodestino')[0]._value,
                        ideBinarioDestinazione: Ext.ComponentQuery.query('#binarioDestino')[0]._value,
                        massa: Ext.ComponentQuery.query('#massa')[0]._value,
                        lunghezza: Ext.ComponentQuery.query('#lunghezza')[0]._value,
                        checkMp: Ext.ComponentQuery.query('#checkMP')[0]._checked === true ? 1 : 0,
                        noteMP: Ext.ComponentQuery.query('#notaMP')[0]._value,
                        checkTES: Ext.ComponentQuery.query('#checkTS')[0]._checked === true ? 1 : 0,
                        checkTEP: Ext.ComponentQuery.query('#checkTP')[0]._checked === true ? 1 : 0,
                        checkTE: Ext.ComponentQuery.query('#checkTE')[0]._checked === true ? 1 : 0,
                        noteTE: Ext.ComponentQuery.query('#noteTE')[0]._value,
                        checkTC: Ext.ComponentQuery.query('#checkTC')[0]._checked === true ? 1 : 0,
                        noteTC: Ext.ComponentQuery.query('#noteTC')[0]._value,
                        note: Ext.ComponentQuery.query('#note')[0]._value,
                        madOra: oraMad,
                        offeSetMad: Ext.ComponentQuery.query('#offsetMad')[0]._value,
                        consegnaOra: oraConsegna,
                        offSetConsegna: Ext.ComponentQuery.query('#offsetConsegna')[0]._value,
                        operazioniOra: oraOperazioni,
                        offSetOpe: Ext.ComponentQuery.query('#offsetermineOp')[0]._value,
                        trasferimentoOra: oraTrasferimento,
                        offSettrasferimento: Ext.ComponentQuery.query('#offsetTrasMat')[0]._value,
                        aggsgOra: oraAggsgO,
                        offSetAggSga: Ext.ComponentQuery.query('#offsetAggSga')[0]._value,
                        rimozioneOra: oraRimozione,
                        offsetRim: Ext.ComponentQuery.query('#offsetApplicrim')[0]._value,
                        operazioniManovra: Ext.ComponentQuery.query('#operazione')[0]._value,
                        ideLoc: arrayLocalitaDisponibili.find(x => x.value === Ext.ComponentQuery.query('#cmbRtoIILocalita')[0]._value).ideLoc,
                        ideTipTra: rtoSchede.data.items[0].data.rto.ideTipTra,
                        operazioniManovraOld: arraySelezione,
                        ideManovra: idManovra,
                        prgPrc: Ext.ComponentQuery.query('#cmbRtoIILocalita')[0]._value
                    },
                    callback: function (records, success, operation) {
                        if (success) {
                            var returnObj = Ext.decode(operation.responseText);
                            //modifica gestione errore
                            if (returnObj.resp === "KO") {
                                setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                                Ext.Msg.alert('Modifica manovra fallito', returnObj.msgErr);
                                Ext.ComponentQuery.query('#areaOrigine')[0].setOptions(arrayAree.filter(x => x.ideLoc === arrayLocalitaDisponibili.find(x => x.value === Ext.ComponentQuery.query('#cmbRtoIILocalita')[0]._value).ideLoc));
                                Ext.ComponentQuery.query('#areaDestino')[0].setOptions(arrayAree.filter(x => x.ideLoc === arrayLocalitaDisponibili.find(x => x.value === Ext.ComponentQuery.query('#cmbRtoIILocalita')[0]._value).ideLoc));
                            }
                            else {
                                Ext.toast({ message: 'Manovra modificata', timeout: 4000 });
                                caricaPercorso();
                                props.aggiornaStateModifica(true);
                                aggiornaControllo(true);
                                chiudiSchedaAggiorna();
                            }
                        }
                        else {
                            setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso..." });
                            Ext.Msg.alert('Modifica manovra fallito', 'Modifica manovra fallito');
                            Ext.ComponentQuery.query('#areaOrigine')[0].setOptions(arrayAree.filter(x => x.ideLoc === arrayLocalitaDisponibili.find(x => x.value === Ext.ComponentQuery.query('#cmbRtoIILocalita')[0]._value).ideLoc));
                            Ext.ComponentQuery.query('#areaDestino')[0].setOptions(arrayAree.filter(x => x.ideLoc === arrayLocalitaDisponibili.find(x => x.value === Ext.ComponentQuery.query('#cmbRtoIILocalita')[0]._value).ideLoc));
                        }
                    }
                })
            }
        }
    };

    const handleCancel = (btn) => {
        if (props.inEdit) {
            props.aggiornaStateModificaManovra('A');
        }
        chiudiScheda();
    };

    const bkColor = "background:#f8f8f8";


    const caricaStore = () => {
        caricamentoLocalitaDisponibili(undefined).then(function () {
            Ext.Ajax.request({
                url: 'Rto/LeggiManovreImpegnoImpianto',
                method: 'GET',
                params: {
                    ideRTO: rtoSchede.data.items[0].data.rto.ideRTO,
                    inEdit: props.inEdit
                },
                callback: function (records, success, operation) {
                    var returnObj = Ext.decode(operation.responseText);
                    storeManovreImpianto.loadData(returnObj);
                    Ext.ComponentQuery.query('#gridManovre')[0].setStore(storeManovreImpianto);
                }
            });
        })

    }

    if (stateLoadMask.ricarica === true)
        caricaStore();


    function onNuovoManovraImpiantoClick() {
        state.showScheda = false;
        state.edit = false;
        state.selectedRow = null;
        state.modifica = false;
        setState({ ...state });
        Ext.ComponentQuery.query('#cmbRtoIILocalita')[0].setValue("");
        caricamentoLocalitaDisponibili().then(function () {
            props.aggiornaStateModificaManovra('M');
            state.showScheda = true;
            state.edit = true;
            state.selectedRow = null;
            state.modifica = false;
            setState({ ...state });
        })

    }

    function modificaGiorniCircolazione(grid, info) {
        var dialog = Ext.create({
            xtype: 'dialog',
            title: 'Dettaglio Giorni',
            width: '500px',
            layout: 'fit',
            closable: "true",
            listeners: {
                close: function (dialog) {
                    dialog.destroy();
                    refreshGridPeriodicitaManII();
                    aggiornaControllo(true);
                    Ext.Ajax.request({
                        url: 'Rto/CheckPeriodicitaSML',
                        method: 'POST',
                        params: {
                            idePer: manovraSelezionata,
                            ideTipo: 3
                        },
                        callback: function (records, success, operation) {
                            var returnCheck = Ext.decode(operation.responseText);
                            if (returnCheck.length > 0) {
                                Ext.Msg.alert('Attenzione', returnCheck);
                                aggiornaStateCheck(false);
                            }
                            else {
                                aggiornaStateCheck(true);
                            }
                        }
                    });
                }
            },
            items: [
                {
                    xtype: 'formpanel',
                    cls: 'PnlSectionL1',
                    ref: form => form = (form),
                    itemId: 'formDettaglioGiorniIIManovre',
                    items: [
                        {
                            xtype: 'fieldset',
                            defaults: { labelAlign: "left" },
                            items: [
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Sempre",
                                            flex: 1,
                                            checked: info.record.data.flgSmr,
                                            margin: "5 5 5 5",
                                            name: "checkSmrIIManovre",
                                            itemId: "checkSmrIIManovre",
                                            listeners: {
                                                change: function (checkbox, newValue, oldValue, eOpts) {
                                                    if (newValue) {
                                                        Ext.ComponentQuery.query('#checkLndIIManovre')[0].disable();
                                                        Ext.ComponentQuery.query('#checkMrtIIManovre')[0].disable();
                                                        Ext.ComponentQuery.query('#checkMrcIIManovre')[0].disable();
                                                        Ext.ComponentQuery.query('#checkGvdIIManovre')[0].disable();
                                                        Ext.ComponentQuery.query('#checkVnrIIManovre')[0].disable();
                                                        Ext.ComponentQuery.query('#checkSbtIIManovre')[0].disable();
                                                        Ext.ComponentQuery.query('#checkDmnIIManovre')[0].disable();
                                                        Ext.ComponentQuery.query('#checkLavIIManovre')[0].disable();
                                                        Ext.ComponentQuery.query('#checkFesIIManovre')[0].disable();
                                                        Ext.ComponentQuery.query('#checkPtfIIManovre')[0].disable();
                                                        Ext.ComponentQuery.query('#checkPofIIManovre')[0].disable();
                                                        Ext.ComponentQuery.query('#checkSegPofIIManovre')[0].disable();
                                                        Ext.ComponentQuery.query('#checkLndIIManovre')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkMrtIIManovre')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkMrcIIManovre')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkGvdIIManovre')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkVnrIIManovre')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkSbtIIManovre')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkDmnIIManovre')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkLavIIManovre')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkFesIIManovre')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkPtfIIManovre')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkPofIIManovre')[0].setChecked(false);
                                                        Ext.ComponentQuery.query('#checkSegPofIIManovre')[0].setChecked(false);
                                                    }
                                                    else {
                                                        Ext.ComponentQuery.query('#checkLndIIManovre')[0].enable();
                                                        Ext.ComponentQuery.query('#checkMrtIIManovre')[0].enable();
                                                        Ext.ComponentQuery.query('#checkMrcIIManovre')[0].enable();
                                                        Ext.ComponentQuery.query('#checkGvdIIManovre')[0].enable();
                                                        Ext.ComponentQuery.query('#checkVnrIIManovre')[0].enable();
                                                        Ext.ComponentQuery.query('#checkSbtIIManovre')[0].enable();
                                                        Ext.ComponentQuery.query('#checkDmnIIManovre')[0].enable();
                                                        Ext.ComponentQuery.query('#checkLavIIManovre')[0].enable();
                                                        Ext.ComponentQuery.query('#checkFesIIManovre')[0].enable();
                                                        Ext.ComponentQuery.query('#checkPtfIIManovre')[0].enable();
                                                        Ext.ComponentQuery.query('#checkPofIIManovre')[0].enable();
                                                        Ext.ComponentQuery.query('#checkSegPofIIManovre')[0].enable();
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Lavorativi",
                                            flex: 1,
                                            checked: info.record.data.flgLav,
                                            margin: "5 5 5 5",
                                            name: "checkLavIIManovre",
                                            itemId: "checkLavIIManovre",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Lunedi",
                                            flex: 1,
                                            checked: info.record.data.flgLnd,
                                            margin: "5 5 5 5",
                                            name: "checkLndIIManovre",
                                            itemId: "checkLndIIManovre",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        },
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Festivi",
                                            flex: 1,
                                            checked: info.record.data.flgFes,
                                            margin: "5 5 5 5",
                                            name: "checkFesIIManovre",
                                            itemId: "checkFesIIManovre",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Martedi",
                                            flex: 1,
                                            checked: info.record.data.flgMrt,
                                            margin: "5 5 5 5",
                                            name: "checkMrtIIManovre",
                                            itemId: "checkMrtIIManovre",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        },
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Prefestivi",
                                            flex: 1,
                                            checked: info.record.data.flgPtf,
                                            margin: "5 5 5 5",
                                            name: "checkPtfIIManovre",
                                            itemId: "checkPtfIIManovre",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Mercoledi",
                                            flex: 1,
                                            checked: info.record.data.flgMrc,
                                            margin: "5 5 5 5",
                                            name: "checkMrcIIManovre",
                                            itemId: "checkMrcIIManovre",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        },
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Post Festivi",
                                            flex: 1,
                                            checked: info.record.data.flgPof,
                                            margin: "5 5 5 5",
                                            name: "checkPofIIManovre",
                                            itemId: "checkPofIIManovre",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Giovedi",
                                            flex: 1,
                                            checked: info.record.data.flgGvd,
                                            margin: "5 5 5 5",
                                            name: "checkGvdIIManovre",
                                            itemId: "checkGvdIIManovre",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        },
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Seguenti Post Festivi",
                                            flex: 1,
                                            checked: info.record.data.flgSegPof,
                                            margin: "5 5 5 5",
                                            name: "checkSegPofIIManovre",
                                            itemId: "checkSegPofIIManovre",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    width: '49%',
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Venerdi",
                                            flex: 1,
                                            checked: info.record.data.flgVnr,
                                            margin: "5 5 5 5",
                                            name: "checkVnrIIManovre",
                                            itemId: "checkVnrIIManovre",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    width: '49%',
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Sabato",
                                            flex: 1,
                                            checked: info.record.data.flgSbt,
                                            margin: "5 5 5 5",
                                            name: "checkSbtIIManovre",
                                            itemId: "checkSbtIIManovre",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                },
                                {
                                    xtype: 'panel',
                                    layout: "hbox",
                                    margin: "5 5 5 5",
                                    width: '49%',
                                    defaults: { labelAlign: "left" },
                                    items: [
                                        {
                                            xtype: 'checkboxfield',
                                            labelWidth: "80%",
                                            label: "Domenica",
                                            flex: 1,
                                            checked: info.record.data.flgDmn,
                                            margin: "5 5 5 5",
                                            name: "checkDmnIIManovre",
                                            itemId: "checkDmnIIManovre",
                                            disabled: info.record.data.flgSmr === true ? true : false
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            xtype: 'toolbar',
                            shadow: false,
                            cls: 'PnlSectionL1',
                            docked: 'bottom',
                            layout: { type: 'hbox', pack: 'right' },
                            items: [
                                {
                                    xtype: 'button',
                                    cls: 'appButton',
                                    text: 'RESET',
                                    handler: () =>
                                        Ext.ComponentQuery.query('#formDettaglioGiorniIIManovre')[0].reset()
                                },
                                {
                                    xtype: 'button',
                                    cls: 'appButton',
                                    text: 'OK',
                                    handler: (btn) => {
                                        const values = btn.up('formpanel').getValues();
                                        if (!((values.checkSmrIIManovre === false || values.checkSmrIIManovre === null) && (values.checkLndIIManovre === false || values.checkLndIIManovre === null) && (values.checkMrtIIManovre === false || values.checkMrtIIManovre === null) && (values.checkMrcIIManovre === false || values.checkMrcIIManovre === null) && (values.checkGvdIIManovre === false || values.checkGvdIIManovre === null) && (values.checkVnrIIManovre === false || values.checkVnrIIManovre === null) && (values.checkSbtIIManovre === false || values.checkSbtIIManovre === null) && (values.checkDmnIIManovre === false || values.checkDmnIIManovre === null) && (values.checkLavIIManovre === false || values.checkLavIIManovre === null) && (values.checkFesIIManovre === false || values.checkFesIIManovre === null) && (values.checkPtfIIManovre === false || values.checkPtfIIManovre === null) && (values.checkPofIIManovre === false || values.checkPofIIManovre === null) && (values.checkSegPofIIManovre === false || values.checkSegPofIIManovre === null))) {
                                            info.record.data.flgSmr = values.checkSmrIIManovre === true ? true : false;
                                            info.record.data.flgLnd = values.checkLndIIManovre === true ? true : false;
                                            info.record.data.flgMrt = values.checkMrtIIManovre === true ? true : false;
                                            info.record.data.flgMrc = values.checkMrcIIManovre === true ? true : false;
                                            info.record.data.flgGvd = values.checkGvdIIManovre === true ? true : false;
                                            info.record.data.flgVnr = values.checkVnrIIManovre === true ? true : false;
                                            info.record.data.flgSbt = values.checkSbtIIManovre === true ? true : false;
                                            info.record.data.flgDmn = values.checkDmnIIManovre === true ? true : false;
                                            info.record.data.flgLav = values.checkLavIIManovre === true ? true : false;
                                            info.record.data.flgFes = values.checkFesIIManovre === true ? true : false;
                                            info.record.data.flgPtf = values.checkPtfIIManovre === true ? true : false;
                                            info.record.data.flgPof = values.checkPofIIManovre === true ? true : false;
                                            info.record.data.flgSegPof = values.checkSegPofIIManovre === true ? true : false;
                                            var periodicitaManovreIIObject = info.record.data;
                                            periodicitaManovreIIObject.ideLoc = localitaSelezionata;
                                            periodicitaManovreIIObject.ideParent = manovraSelezionata;
                                            var periodicitaJson = Ext.encode(periodicitaManovreIIObject);
                                            setStateLoadMask({ hidden: false, message: "Salvataggio in corso...", ricarica: true });
                                            Ext.Ajax.request({
                                                url: 'Rto/ModificaPeriodicitaManovreImpegnoImpianto',
                                                method: 'POST',
                                                params: {
                                                    ideUte: user.ideUte,
                                                    periodicitaDes: periodicitaJson
                                                },
                                                callback: function (records, success, operation) {
                                                    if (success) {
                                                        var returnObj = Ext.decode(operation.responseText);
                                                        //modifica gestione errore
                                                        if (returnObj.resp === "KO") {
                                                            setStateLoadMask({ hidden: true, message: "Eliminazione servizio in corso...", ricarica: false });
                                                            Ext.Msg.alert('Modifica periodicita manovra fallita', returnObj.msgErr);
                                                        }
                                                        else {
                                                            dialog.destroy();
                                                            refreshGridPeriodicitaManII();
                                                            Ext.toast({ message: 'Periodicita manovra modificata', timeout: 4000 });
                                                            aggiornaControllo(true);
                                                            Ext.Ajax.request({
                                                                url: 'Rto/CheckPeriodicitaSML',
                                                                method: 'POST',
                                                                params: {
                                                                    idePer: manovraSelezionata,
                                                                    ideTipo: 3
                                                                },
                                                                callback: function (records, success, operation) {
                                                                    var returnCheck = Ext.decode(operation.responseText);
                                                                    if (returnCheck.length > 0) {
                                                                        Ext.Msg.alert('Attenzione', returnCheck);
                                                                        aggiornaStateCheck(false);
                                                                    }
                                                                    else {
                                                                        aggiornaStateCheck(true);
                                                                    }
                                                                }
                                                            });

                                                        }
                                                    }
                                                    else {
                                                        Ext.Msg.alert('Modifica periodicita manovra fallita', 'Modifica periodicita manovra fallita');
                                                        setStateLoadMask({ hidden: true, message: "Eliminazione servizio in corso...", ricarica: false });
                                                    }

                                                }
                                            });
                                        }
                                        else {
                                            Ext.Msg.alert('Dettaglio Giorni', 'Scegliere almeno un giorno')
                                        }


                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        });

        dialog.show();
    }

    function caricamentoLocalitaDisponibili(recordApp) {
        return new Promise(function (resolve, reject) {
            setStateLoadMask({ hidden: false, message: "Caricamento localita disponibili in corso...", ricarica: false });
            Ext.Ajax.request({
                url: 'Rto/GetAnagraficheII',
                timeout: 600000,
                method: 'POST',
                params: { ideRto: rtoSchede.data.items[0].data.rto.ideRTO },
                callback: function (resp, success, operation) {
                    if (success) {
                        var resptext = Ext.decode(operation.responseText);
                        arrayAree = [];
                        arrayAreeApp = [];
                        for (let i = 0; i < resptext.areeManovraLoc.length; i++) {
                            let app = { text: resptext.areeManovraLoc[i].noM_ARE_IMT, value: resptext.areeManovraLoc[i].idE_ARE_IMT, ideLoc: resptext.areeManovraLoc[i].idE_LOC };
                            arrayAree.push(app);
                        }
                        arrayAreeApp = recordApp != undefined ? arrayAree.filter(x => x.ideLoc === recordApp.data.idE_LOC) : arrayAree;
                        arrayBinari = [];
                        arrayBinariOrigine = [];
                        arrayBinariDestino = [];
                        for (let i = 0; i < resptext.binarioFascioManovraImpegnoImpianto.length; i++) {
                            let app = { text: resptext.binarioFascioManovraImpegnoImpianto[i].noM_BIN_IMT, value: resptext.binarioFascioManovraImpegnoImpianto[i].idE_BIN_IMT, ideFascio: resptext.binarioFascioManovraImpegnoImpianto[i].idE_FSC_IMT };
                            arrayBinari.push(app);
                        }
                        arrayBinariOrigine = recordApp != undefined ? arrayBinari.filter(x => x.ideFascio === recordApp.data.idE_FASCIO_ORI) : arrayBinari;
                        arrayBinariDestino = recordApp != undefined ? arrayBinari.filter(x => x.ideFascio === recordApp.data.idE_FASCIO_DES) : arrayBinari;
                        arrayFasci = [];
                        arrayFasciOrigine = [];
                        arrayFasciDestino = [];
                        for (let i = 0; i < resptext.fascioAreaManovraImpegnoImpianto.length; i++) {
                            let app = { text: resptext.fascioAreaManovraImpegnoImpianto[i].noM_FSC_IMT, value: resptext.fascioAreaManovraImpegnoImpianto[i].idE_FSC_IMT, ideArea: resptext.fascioAreaManovraImpegnoImpianto[i].idE_ARE_IMT };
                            arrayFasci.push(app);
                        }
                        arrayFasciOrigine = recordApp != undefined ? arrayFasci.filter(x => x.ideArea === recordApp.data.idE_AREA_ORI) : arrayFasci;
                        arrayFasciDestino = recordApp != undefined ? arrayFasci.filter(x => x.ideArea === recordApp.data.idE_AREA_DES) : arrayFasci;
                        arrayOperazioni = [];
                        for (let i = 0; i < resptext.tipoOperazioneManovra.length; i++) {
                            let app = { text: resptext.tipoOperazioneManovra[i].deS_OPE_MVN, value: resptext.tipoOperazioneManovra[i].idE_OPE_MVN };
                            arrayOperazioni.push(app);
                        }
                        arrayGestori = [];
                        for (let i = 0; i < resptext.gestoreManovraImpegnoImpianto.length; i++) {
                            let app = { text: resptext.gestoreManovraImpegnoImpianto[i].noM_GSR_MVN, value: resptext.gestoreManovraImpegnoImpianto[i].idE_GSR_MVN };
                            arrayGestori.push(app);
                        }
                        Ext.Ajax.request({
                            url: 'Rto/GetLocalitaDisponibiliII',
                            method: 'POST',
                            params: { ideRto: rtoSchede.data.items[0].data.rto.ideRTO, impegnoImpianto: Ext.encode(resptext.impianti) },
                            callback: function (resp, success, operation) {
                                if (success) {
                                    var resptextBefore = Ext.decode(operation.responseText);
                                    arrayLocalitaDisponibili = [];
                                    for (let i = 0; i < resptextBefore.length; i++) {
                                        let app = { text: resptextBefore[i].nomLoc, value: resptextBefore[i].prgPrc, idePrc: resptextBefore[i].idePrcRTO, offSetArr: resptextBefore[i].ofsArr, tipPass: resptextBefore[i].sigPas, ideLoc: resptextBefore[i].ideLoc, idePrcRto: resptextBefore[i].idePrcRTO };
                                        arrayLocalitaDisponibili.push(app);
                                    }

                                    setStateLoadMask({ hidden: true, message: "Caricamento localita disponibili in corso...", ricarica: false });
                                    resolve();
                                }
                            }
                        })
                    }
                }
            });
        })

    }

    function chiudiScheda() {
        state.showScheda = false;
        state.edit = false;
        state.modifica = false;
        state.selectedRow = null;
        setState({ ...state });
    }

    function chiudiSchedaAggiorna() {
        state.showScheda = false;
        state.edit = false;
        state.modifica = false;
        state.selectedRow = null;
        setState({ ...state });
        setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso...", ricarica: true });
    }


    function onDettaglioManovraClick(grid, info) {
        state.selectedRow = info.record;
        state.showScheda = false;
        state.edit = false;
        state.modifica = false;
        setState({ ...state });
        caricamentoLocalitaDisponibili(info.record).then(function () {
            state.selectedRow = info.record;
            state.showScheda = true;
            state.edit = false;
            state.modifica = false;
            setState({ ...state });
            //Ext.ComponentQuery.query('#cmbRtoIILocalita')[0].setValue(arrayLocalitaDisponibili.find(x => x.idePrcRto === info.record.data.idE_PRC_RTO).value);
        })

    }

    function onModificaManovraClick(grid, info) {
        idManovra = info.record.data.idE_MAN_IPG_IMP;
        state.selectedRow = info.record;
        state.showScheda = false;
        state.edit = false;
        state.modifica = false;
        setState({ ...state });
        caricamentoLocalitaDisponibili(info.record).then(function () {
            props.aggiornaStateModificaManovra('M');
            state.selectedRow = info.record;
            state.showScheda = true;
            state.edit = true;
            state.modifica = true;
            setState({ ...state });
            //Ext.ComponentQuery.query('#cmbRtoIILocalita')[0].setValue((arrayLocalitaDisponibili.find(x => x.idePrcRto === info.record.data.idE_PRC_RTO).value));
        })
    }
    function onEliminaManovraClick(grid, info) {
        state.showScheda = false;
        state.edit = false;
        state.selectedRow = null;
        state.modifica = false;
        setState({ ...state });
        setStateLoadMask({ hidden: false, message: "Attendere eliminazione in corso...", ricarica: false });
        Ext.Ajax.request({
            url: 'Rto/CancellaManovreImpegnoImpianto',
            method: 'POST',
            params: {
                ideUte: user.ideUte,
                ideManovra: info.record.data.idE_MAN_IPG_IMP
            },
            callback: function (records, success, operation) {
                if (success) {
                    var returnObj = Ext.decode(operation.responseText);
                    //modifica gestione errore
                    if (returnObj.resp === "KO") {
                        setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso...", ricarica: false });
                        Ext.Msg.alert('Eliminazione manovra fallito', returnObj.msgErr)
                    }
                    else {
                        setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso...", ricarica: true });
                        Ext.toast({ message: 'Manovra eliminata', timeout: 4000 });
                        caricaPercorso();
                        aggiornaControllo(true);
                        Ext.Ajax.request({
                            url: 'Rto/CheckPeriodicitaImpegnoImpianto',
                            method: 'POST',
                            params: {
                                ideRto: rtoSchede.data.items[0].data.rto.ideRTO
                            },
                            callback: function (records, success, operation) {
                                var returnCheck = Ext.decode(operation.responseText);
                                if (returnCheck.length > 0) {
                                    aggiornaStateCheck(false);
                                }
                                else {
                                    aggiornaStateCheck(true);
                                }
                            }
                        });
                    }
                }
                else {
                    setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso...", ricarica: false });
                    Ext.Msg.alert('Eliminazione manovra fallito', 'Inserimento manovra fallito');
                }
            }
        })
    }

    function modificaPeriodicita(grid, info) {
        state.showScheda = false;
        state.edit = false;
        state.selectedRow = null;
        state.modifica = false;
        setState({ ...state });
        setStateLoadMask({ hidden: false, message: "Attendere caricamento periodicita in corso...", ricarica: false });
        Ext.Ajax.request({
            url: 'Rto/LeggiManovreImpegnoImpianto',
            method: 'GET',
            params: {
                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
            },
            callback: function (records, success, operation) {
                var returnObj = Ext.decode(operation.responseText);
                let arrayCalendario = [];
                if (returnObj.find(x => x.idE_MAN_IPG_IMP === info.record.data.idE_MAN_IPG_IMP).snG_PER_ERR !== "") {
                    arrayCalendario.push(returnObj.find(x => x.idE_MAN_IPG_IMP === info.record.data.idE_MAN_IPG_IMP));
                    setCalendars(arrayCalendario);
                }
                else {
                    setCalendars(null);
                }
                caricaPeriodicitaManovreImpianto(info.record.data.idE_MAN_IPG_IMP);
                setIsCalendarioVisible(true);
                titolo = "Periodicita manovra " + info.record.data.noM_LOC;
                manovraSelezionata = info.record.data.idE_MAN_IPG_IMP;
                localitaSelezionata = info.record.data.idE_LOC;
                setStateLoadMask({ hidden: true, message: "Attendere caricamento periodicita in corso...", ricarica: false });
            }
        });

    }

    const caricaPeriodicitaManovreImpianto = (idE_MAN_IPG_IMP) => {
        var periodicitaByDB = null;
        Ext.Ajax.request({
            url: 'Rto/GetImpegnoImpianto',
            method: 'GET',
            params: {
                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
            },
            callback: function (resp, success, operation) {
                periodicitaByDB = Ext.decode(operation.responseText);
                storePeriodicitaIIManovre.loadData(periodicitaByDB.periodicitaImpegnoImpiantoManovre.filter(function (value) {
                    return value.ideParent === idE_MAN_IPG_IMP;
                }))
            }
        })
    }

    const renderListOfCalendar = (calendars) => {
        const cal = calendars.map((name, i) => <><Panel collapsible={false} key={`sintesiMan${i}`} cls="calendar-container">
            <br />
            <div className="periodicitaHeader">
                <CalendarioSintetico
                    giorniTreno={calendars[i].snG_PER_ERR ? calendars[i].snG_PER_ERR.split('1').length - 1 : '0'}
                    periodicitaSintetico={calendars[i].snG_GIO_SET}
                />
            </div>
            <Button cls="plus-minus-button" itemId={`calendarioExpButminIIMan${i}`} iconCls="x-fa fa-minus" handler={function () {
                Ext.ComponentQuery.query(`#calendarioExpButPlusIIMan${i}`)[0].show();
                this.hide();
                Ext.ComponentQuery.query(`#calendarioExpIIMan${i}`)[0].hide();
            }}></Button>
            <Button cls="plus-minus-button" itemId={`calendarioExpButPlusIIMan${i}`} hidden={true} iconCls="x-fa fa-plus" handler={function () {
                Ext.ComponentQuery.query(`#calendarioExpButminIIMan${i}`)[0].show();
                this.hide();
                Ext.ComponentQuery.query(`#calendarioExpIIMan${i}`)[0].show();
            }}></Button>
        </Panel>
            <Panel itemId={`calendarioExpIIMan${i}`} hidden={false} collapsible={false}>
                <Calendario
                    dataInizio={calendars[i].daT_INI_VAL}
                    dataFine={calendars[i].daT_FIN_VAL}
                    periodicita={calendars[i].snG_PER_ERR}
                    dataAttProvvedimento={null}
                    periodicitaSintetico={calendars[i].snG_GIO_SET}
                    IsNonEffettuabile={true}
                    isAttivazioneProvvedimento={false}
                />
            </Panel>
        </>)
        return cal;
    }

    function refreshGridPeriodicitaManII() {
        setStateLoadMask({ hidden: false, message: "Refresh griglia periodicita manovre...", ricarica: false });
        var periodicitaByDB = null;
        Ext.Ajax.request({
            url: 'Rto/GetImpegnoImpianto',
            method: 'GET',
            params: {
                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
            },
            callback: function (resp, success, operation) {
                periodicitaByDB = Ext.decode(operation.responseText);
                storePeriodicitaIIManovre.loadData(periodicitaByDB.periodicitaImpegnoImpiantoManovre.filter(function (value) {
                    return value.ideParent === manovraSelezionata;
                }));
                Ext.ComponentQuery.query('#gridPeriodicitaIIManovre')[0].setStore(storePeriodicitaIIManovre);
                Ext.Ajax.request({
                    url: 'Rto/LeggiManovreImpegnoImpianto',
                    method: 'GET',
                    params: {
                        ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                    },
                    callback: function (records, success, operation) {
                        var returnObj = Ext.decode(operation.responseText);
                        let arrayCalendario = [];
                        if (returnObj.find(x => x.idE_MAN_IPG_IMP === manovraSelezionata).snG_PER_ERR !== "") {
                            arrayCalendario.push(returnObj.find(x => x.idE_MAN_IPG_IMP === manovraSelezionata));
                            setCalendars(arrayCalendario);
                        }
                        else {
                            setCalendars(null);
                        }
                        setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso...", ricarica: true });
                    }
                })
            }
        });
    }

    function onNuovaPeriodicitaIIManClick() {
        var grid = Ext.ComponentQuery.query('#gridPeriodicitaIIManovre')[0];
        var store = grid.getStore();
        var record = Ext.create(periodicitaModel, {});
        record.data.datIniPer = rangeDateStart;
        record.data.datFinPer = rangeDateEnd;
        record.data.flgSmr = true;
        record.data.ideOpv = 0;
        store.insert(grid.getStore().data.length, record);
        grid.getPlugin('rowedit').startEdit(record, 1);
    }

    function onEliminaPeriodicitaManovraIIClick(grid, info) {
        Ext.Msg.confirm('Confirm', "Procedere con l'eliminazione della periodicita manovra?", function (button) {

            if (button === 'yes') {
                setStateLoadMask({ hidden: false, message: "Attendere eliminazione in corso...", ricarica: false });
                var periodicitaByDB = null;
                Ext.Ajax.request({
                    url: 'Rto/GetImpegnoImpianto',
                    method: 'GET',
                    params: {
                        ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                    },
                    callback: function (resp, success, operation) {
                        const idPeriodicita = info.record.data.idePerImpegnoImpianto;
                        periodicitaByDB = Ext.decode(operation.responseText);

                        if (periodicitaByDB.periodicitaImpegnoImpiantoManovre.find(per => per.idePerImpegnoImpianto === idPeriodicita) != null) {
                            var periodicitaJson = Ext.encode(periodicitaByDB.periodicitaImpegnoImpiantoManovre.find(per => per.idePerImpegnoImpianto === idPeriodicita));
                            Ext.Ajax.request({
                                url: 'Rto/CancellaPeriodicitaManovreImpegnoImpianto',
                                method: 'POST',
                                params: {
                                    ideUte: user.ideUte,
                                    periodicitaDes: periodicitaJson
                                },
                                callback: function (records, success, operation) {
                                    if (success) {
                                        var returnObj = Ext.decode(operation.responseText);
                                        //modifica gestione errore
                                        if (returnObj.resp === "KO") {
                                            Ext.Msg.alert('Elimina periodicita manovra fallito', returnObj.msgErr)
                                            setStateLoadMask({ hidden: true, message: "Attendere eliminazione in corso...", ricarica: false });
                                        }
                                        else {
                                            refreshGridPeriodicitaManII();
                                            Ext.toast({ message: 'Periodicita manovra eliminata', timeout: 4000 });
                                            aggiornaControllo(true);
                                            Ext.Ajax.request({
                                                url: 'Rto/CheckPeriodicitaImpegnoImpianto',
                                                method: 'POST',
                                                params: {
                                                    ideRto: rtoSchede.data.items[0].data.rto.ideRTO
                                                },
                                                callback: function (records, success, operation) {
                                                    var returnCheck = Ext.decode(operation.responseText);
                                                    if (returnCheck.length > 0) {
                                                        aggiornaStateCheck(false);
                                                    }
                                                    else {
                                                        aggiornaStateCheck(true);
                                                    }
                                                }
                                            });
                                        }
                                    }
                                    else {
                                        Ext.Msg.alert('Elimina periodicita manovra fallito', 'Elimina periodicita manovra fallito');
                                        setStateLoadMask({ hidden: true, message: "Attendere eliminazione in corso...", ricarica: false });
                                    }
                                }
                            });
                        }
                        else {
                            Ext.ComponentQuery.query('#gridPeriodicitaIIManovre')[0].getStore().remove(info.record);
                            Ext.toast({ message: 'Periodicita manovra eliminata', timeout: 4000 });
                        }
                    }
                })

            }
        })

    }

    function onAggiungiPeriodicitaManovraIIClick(grid, info) {

        var store = grid.getStore();
        var record = Ext.create(periodicitaModel, {});
        record.data.datIniPer = rangeDateStart;
        record.data.datFinPer = rangeDateEnd;
        record.data.flgSmr = true;
        record.data.ideOpv = 0;
        store.insert(info.cell.row._recordIndex + 1, record);
        grid.getPlugin('rowedit').startEdit(record, 1);
    }

    function onModificaPeriodicitaManovraIIClick(grid, info) {

        var rowediting = grid.getPlugin('rowedit');
        if (rowediting.editing === false) {
            var record = info.record;
            rowediting.startEdit(record, 2);
        }
    }

    function handleCollapse(panel, direction, eOpts) {
        Ext.ComponentQuery.query('#nuovaManovra')[0].setHidden(true);
        Ext.ComponentQuery.query('#containerManovreII')[0].setCls("container-spinner");
    };

    function handleExpand(panel, direction, eOpts) {
        if (props.inEdit === true)
            Ext.ComponentQuery.query('#nuovaManovra')[0].setHidden(false);
        Ext.ComponentQuery.query('#containerManovreII')[0].setCls("container-spinner PnlSectionL2B");
    };

    return (
        <Container itemId="containerManovreII" margin='2px 0px 2px 0px' border={true} cls="container-spinner" height="100%" width="100%" layout="fit" >
            <Loadmask
                cls="custom-grid"
                itemId="loadMaskRtoDet"
                hidden={stateLoadMask.hidden}
                indicator={true}
                message={`<div class="custom-spinner"><i class="fas fa-spinner fa-spin"></i><br/><span class=spinMessage>${stateLoadMask.message}</span></div>`}
                modal={true}
            />
            <Panel
                title="Manovre"
                titleCollapse={true}
                iconCls="x-fa fa-shuffle"
                collapsible={{ collapsed: true, direction: 'top' }}
                onCollapse={handleCollapse}
                onExpand={handleExpand}
                cls='PnlSectionL2 iconPanelsRTO'
                tools={[
                    {
                        iconCls: 'x-fa x-fa fa-plus', tooltip: 'Nuovo', itemId: "nuovaManovra", hidden: "true", handler: onNuovoManovraImpiantoClick.bind(this)
                    }
                    /* { type: 'menu', handler: toolHandler }*/
                ]}
            >
                <Container
                    layout="vbox" fitToParent={true} cls="column-in-panel"
                >
                    <Grid
                        itemId="gridManovre"
                        minHeight={100}
                        maxHeight={200}
                        markDirty={true}
                        disabled={isCalendarioVisible || state.showScheda}
                        flex={1}
                        scrollable
                        selectable={{ checkbox: false, mode: 'single' }}
                        store={storeManovreImpianto}
                        plugins={{
                            gridfilters: {}
                        }}
                        itemConfig={{
                            viewModel: true
                        }}
                    >
                        <Column itemId='actionRTOManovre' editable={false} text="Azioni" flex={1}
                            cell={{

                                tools: {
                                    modificaManovre: {

                                        hidden: !props.inEdit,

                                        handler: onModificaManovraClick.bind(this),

                                        bind: {

                                            iconCls: "x-fa fa-edit",

                                            tooltip: 'Modifica'
                                        }

                                    },

                                    dettaglioManovre: {

                                        handler: onDettaglioManovraClick.bind(this),

                                        bind: {

                                            iconCls: "x-fa fa-folder-open",

                                            tooltip: 'Dettagli'
                                        }

                                    },

                                    eliminaManovre: {

                                        hidden: !props.inEdit,

                                        handler: onEliminaManovraClick.bind(this),

                                        bind: {

                                            iconCls: "x-fa fa-trash",

                                            tooltip: 'Elimina'
                                        }

                                    }

                                }

                            }}
                        />
                        <Column text="Localit&aacute" dataIndex="noM_LOC" filter='string' flex={1}
                        />
                        <Column text="Tipo Gestione" dataIndex="auT_PRD" flex={1} renderer={function (value) { return (value === 1) ? "Autoproduzione" : "Gestore Manovra"; }}
                        />
                        <Column text="Inizio Attivit&aacute" dataIndex="orA_INI" filter='string' flex={1} editor={{
                            xtype: 'timefield', itemId: 'oraIni', format: 'H:i', maxLength: 5,
                            picker: {
                                meridiem: false,
                                alignPMInside: true
                            }, triggerAction: 'all'

                        }}
                        />
                        <Column text="Area Origine" dataIndex="noM_ARE_ORI" filter='string' flex={1}
                        />
                        <Column text="Fine Attivit&aacute" dataIndex="orA_FIN" filter='string' flex={1}
                        />
                        <Column text="Area Destinazione" dataIndex="noM_AREA_DES" filter='string' flex={1}
                        />
                        <Column text="Periodicit&aacute" renderer={function (value, metaData, record, rowIndex, colIndex, store, view) {
                            return metaData.data.periodicita;
                        }} editable={false} filter='string' flex={1} cell={{

                            tools: {

                                modificaPeriodicita: {

                                    handler: modificaPeriodicita.bind(this),

                                    bind: {

                                        iconCls: "x-fa fa-calendar buttonMerciTool",

                                        tooltip: 'Periodicita'
                                    }
                                }

                            }

                        }} />

                    </Grid>
                    <Container>
                        <Panel>
                            <Formpanel
                                layout="vbox"
                                docked="bottom"
                                bodyStyle={bkColor}
                                hidden={!state.showScheda}
                            >
                                <FieldSet layout="hbox" margin="0px" defaults={{ labelAlign: "left" }} >
                                    <Container layout="vbox" margin="0 10 0 0">
                                        <ComboBoxField
                                            label="Località"
                                            width="340px"
                                            itemId="cmbRtoIILocalita"
                                            typeAhead
                                            labelCls='LabelRiepilogo'
                                            required={true}
                                            labelAlign="top"
                                            options={arrayLocalitaDisponibili}
                                            requiredMessage="Questo campo &egrave; obbligatorio"
                                            disabled={!state.edit}
                                            value={row != null ? arrayLocalitaDisponibili.find(x => x.idePrcRto === row.idE_PRC_RTO).value : " "}
                                            queryMode="local"
                                            listeners=
                                            {
                                                {
                                                    change: function (gridView, value, columnIndex) {
                                                        if (value !== "" && value !== undefined && value !== null) {
                                                            Ext.ComponentQuery.query('#areaOrigine')[0].setOptions(arrayAree.filter(x => x.ideLoc === arrayLocalitaDisponibili.find(x => x.value === value).ideLoc));

                                                            Ext.ComponentQuery.query('#areaDestino')[0].setOptions(arrayAree.filter(x => x.ideLoc === arrayLocalitaDisponibili.find(x => x.value === value).ideLoc));
                                                            if (gridView._selection.data.tipPass == 'O') {
                                                                Ext.ComponentQuery.query('#offesetInizio')[0].setValue(0);
                                                                Ext.ComponentQuery.query('#offesetFine')[0].setValue(0);
                                                                Ext.ComponentQuery.query('#offsetMad')[0].setValue(0);
                                                                Ext.ComponentQuery.query('#offsetConsegna')[0].setValue(0);
                                                            }
                                                            else {
                                                                gridView._selection.data.offSetArr ? Ext.ComponentQuery.query('#offesetInizio')[0].setValue(gridView._selection.data.offSetArr) : Ext.ComponentQuery.query('#offesetInizio')[0].setValue(0);
                                                                gridView._selection.data.offSetArr ? Ext.ComponentQuery.query('#offesetFine')[0].setValue(gridView._selection.data.offSetArr) : Ext.ComponentQuery.query('#offesetFine')[0].setValue(0);
                                                                gridView._selection.data.offSetArr ? Ext.ComponentQuery.query('#offsetMad')[0].setValue(gridView._selection.data.offSetArr) : Ext.ComponentQuery.query('#offsetMad')[0].setValue(0);
                                                                gridView._selection.data.offSetArr ? Ext.ComponentQuery.query('#offsetConsegna')[0].setValue(gridView._selection.data.offSetArr) : Ext.ComponentQuery.query('#offsetConsegna')[0].setValue(0);
                                                            }
                                                        }
                                                    }
                                                }}
                                        />
                                    </Container>
                                    <Container layout="vbox" margin="0 10 0 0">
                                        <ComboBoxField
                                            label="Tipo Gestione"
                                            labelCls='LabelRiepilogo'
                                            itemId='tipoGestione'
                                            required={true}
                                            labelAlign="top"
                                            disabled={!state.edit}
                                            requiredMessage="Questo campo &egrave; obbligatorio"
                                            forceSelection={true}
                                            queryMode="local"
                                            options={
                                                [
                                                    { text: 'Gestore Manovra', value: 0 },
                                                    { text: 'Autoproduzione', value: 1 }
                                                ]
                                            }
                                            listeners=
                                            {
                                                {
                                                    change: function (gridView, value, columnIndex) {
                                                        setIsAutoprod(value === 0 ? false : true);
                                                        arrayAreeApp = [];
                                                        arrayAreeApp = Ext.ComponentQuery.query('#cmbRtoIILocalita')[0]._value !== null ? arrayAree.filter(x => x.ideLoc === ((arrayLocalitaDisponibili.find(x => x.value === Ext.ComponentQuery.query('#cmbRtoIILocalita')[0]._value).ideLoc))) : arrayAree;
                                                    }
                                                }}
                                            value={row != null ? row.auT_PRD : 1} />
                                    </Container>
                                    <Container layout="vbox" margin="0 10 0 0">
                                        <ComboBoxField
                                            label="Gestore"
                                            labelCls='LabelRiepilogo'
                                            width="340px"
                                            itemId='gestore'
                                            required={true}
                                            labelAlign="top"
                                            disabled={!state.edit}
                                            requiredMessage="Questo campo &egrave; obbligatorio"
                                            forceSelection={true}
                                            queryMode="local"
                                            options={arrayGestori}
                                            value={row != null ? row.idE_GESTORE : " "}
                                            hidden={isAutoprod} />
                                    </Container>
                                    <Container layout="vbox" margin="0 10 0 0">
                                        <ComboBoxField
                                            label="Operazioni"
                                            labelCls='LabelRiepilogo'
                                            width="500px"
                                            itemId='operazione'
                                            required={true}
                                            labelAlign="top"
                                            disabled={!state.edit}
                                            requiredMessage="Questo campo &egrave; obbligatorio"
                                            queryMode="local"
                                            options={arrayOperazioni}
                                            value={row != null ? arraySelezione : " "}
                                            multiSelect={true}
                                            hidden={isAutoprod} />
                                    </Container>
                                </FieldSet>
                                <FieldSet layout="hbox" margin="0px" defaults={{ labelAlign: "left" }} >
                                    <TextField label="Periodicit&aacute" value={row != null ? row.periodicita : null} disabled={true} flex={1} />
                                </FieldSet>
                                <FieldSet layout="hbox" margin="0px"  >
                                    <Panel layout="vbox" padding="0 0 0 0" defaults={{ labelAlign: "left" }} bodyStyle={bkColor} flex={1}>
                                        <FieldSet layout="hbox" margin="0px" defaults={{ labelAlign: "left" }} flex={1} >
                                            <TimeField
                                                label="Inizio"
                                                itemId='inizio'
                                                format='H:i'
                                                required={true}
                                                labelAlign="left"
                                                margin="0 0 0 10"
                                                maxLength={5}
                                                disabled={!state.edit}
                                                width="190"
                                                hidden={!isAutoprod}
                                                picker={{
                                                    meridiem: false,
                                                    alignPMInside: true
                                                }}
                                                value={row != null ? row.orA_INI : rtoSchede.data.items[0].data.rto.oraPar}
                                            />
                                            <NumberField margin="0 0 0 20" labelAlign="left" disabled={!state.edit} width="120px" labelWidth="50px" itemId='offesetInizio' hidden={!isAutoprod} label="Offset" value={row != null ? row.ofF_SET_INI : 0} />
                                            <TimeField
                                                label="MAD"
                                                tooltip='MAD'
                                                itemId='mad'
                                                cls="disableErrorTimeField"
                                                labelAlign="left"
                                                maxLength={5}
                                                format='H:i'
                                                margin="0 0 0 10"
                                                disabled={!state.edit}
                                                hidden={isAutoprod}
                                                required={true}
                                                width="310"
                                                labelWidth="220"
                                                picker={{
                                                    meridiem: false,
                                                    alignPMInside: true
                                                }}
                                                value={row != null ? row.maD_ORA : rtoSchede.data.items[0].data.rto.oraPar}
                                            />
                                            <Container margin="0 0 0 10">
                                                <NumberField hidden={isAutoprod} disabled={!state.edit} labelAlign="left" width="120px" label="Offset" itemId='offsetMad' labelwidth={50} value={row != null ? row.maD_OFF_SET : 0} />
                                            </Container>
                                            <ComboBoxField
                                                label="Area"
                                                width="350"
                                                labelWidth="50"
                                                typeAhead
                                                labelAlign="left"
                                                margin="0 0 0 10"
                                                disabled={!state.edit}
                                                options={arrayAreeApp}
                                                labelCls='LabelRiepilogo'
                                                required={true}
                                                forceSelection={true}
                                                value={row != null ? row.idE_AREA_ORI : " "}
                                                requiredMessage="Questo campo &egrave; obbligatorio"
                                                queryMode="local"
                                                itemId="areaOrigine"
                                                listeners=
                                                {
                                                    {
                                                        change: function (gridView, value, columnIndex) {
                                                            Ext.ComponentQuery.query('#fascioOrigine')[0].setOptions(arrayFasci.filter(x => x.ideArea === value));
                                                        }
                                                    }}
                                            />
                                            <ComboBoxField
                                                label="Fascio"
                                                width="200px"
                                                labelWidth="50"
                                                typeAhead
                                                labelAlign="left"
                                                margin="0 0 0 10"
                                                options={arrayFasciOrigine}
                                                disabled={!state.edit}
                                                labelCls='LabelRiepilogo'
                                                value={row != null && row.idE_FASCIO_ORI !== 0 ? row.idE_FASCIO_ORI : null}
                                                queryMode="local"
                                                itemId="fascioOrigine"
                                                listeners=
                                                {
                                                    {
                                                        change: function (gridView, value, columnIndex) {
                                                            Ext.ComponentQuery.query('#binarioOrigine')[0].setOptions(arrayBinari.filter(x => x.ideFascio === value));
                                                        }
                                                    }}
                                            />
                                            <ComboBoxField
                                                label="Binario"
                                                width="200"
                                                labelWidth="50px"
                                                margin="0 0 0 10"
                                                typeAhead
                                                options={arrayBinariOrigine}
                                                labelAlign="left"
                                                disabled={!state.edit}
                                                labelCls='LabelRiepilogo'
                                                value={row != null && row.idE_BINARIO_ORI !== 0 ? row.idE_BINARIO_ORI : null}
                                                queryMode="local"
                                                itemId="binarioOrigine"
                                            />
                                        </FieldSet>
                                        <FieldSet layout="hbox" margin="0px" defaults={{ labelAlign: "left" }} flex={1} >
                                            <TimeField
                                                label="Fine"
                                                itemId='fine'
                                                format='H:i'
                                                disabled={!state.edit}
                                                maxLength={5}
                                                margin="0 0 0 10"
                                                width="190"
                                                labelAlign="left"
                                                required={true}
                                                hidden={!isAutoprod}
                                                picker={{
                                                    meridiem: false,
                                                    alignPMInside: true
                                                }}
                                                value={row != null ? row.orA_FIN : rtoSchede.data.items[0].data.rto.oraPar}
                                            />
                                            <NumberField margin="0 0 0 20" labelAlign="left" disabled={!state.edit} width="120px" labelWidth="50px" itemId='offesetFine' hidden={!isAutoprod} label="Offset" value={row != null ? row.ofF_SET_FIN : 0} />
                                            <TimeField
                                                label="Consegna materiale rotabile"
                                                tooltip='Consegna materiale rotabile'
                                                itemId='consegnaMat'
                                                cls="disableErrorTimeField"
                                                maxLength={5}
                                                labelAlign="left"
                                                margin="0 0 0 10"
                                                format='H:i'
                                                hidden={isAutoprod}
                                                disabled={!state.edit}
                                                required={true}
                                                width="310"
                                                labelWidth="220"
                                                picker={{
                                                    meridiem: false,
                                                    alignPMInside: true
                                                }}
                                                value={row != null ? row.crM_ORA : rtoSchede.data.items[0].data.rto.oraPar}
                                            />
                                            <Container margin="0 0 0 10">
                                                <NumberField hidden={isAutoprod} disabled={!state.edit} width="120px" labelAlign="left" itemId='offsetConsegna' label="Offset" labelwidth={50} value={row != null ? row.crM_OFF_SET : 0} />
                                            </Container>

                                            <ComboBoxField
                                                label="Area"
                                                width="350"
                                                labelWidth="50"
                                                typeAhead
                                                margin="0 0 0 10"
                                                labelAlign="left"
                                                options={arrayAreeApp}
                                                disabled={!state.edit}
                                                labelCls='LabelRiepilogo'
                                                required={true}
                                                requiredMessage="Questo campo &egrave; obbligatorio"
                                                forceSelection={true}
                                                value={row != null ? row.idE_AREA_DES : " "}
                                                queryMode="local"
                                                itemId="areaDestino"
                                                listeners=
                                                {
                                                    {
                                                        change: function (gridView, value, columnIndex) {
                                                            Ext.ComponentQuery.query('#fasciodestino')[0].setOptions(arrayFasci.filter(x => x.ideArea === value));
                                                        }
                                                    }}
                                            />
                                            <ComboBoxField
                                                label="Fascio"
                                                width="200"
                                                labelWidth="50"
                                                typeAhead
                                                options={arrayFasciDestino}
                                                margin="0 0 0 10"
                                                labelAlign="left"
                                                disabled={!state.edit}
                                                labelCls='LabelRiepilogo'
                                                value={row != null && row.idE_FASCIO_DES !== 0 ? row.idE_FASCIO_DES : null}
                                                queryMode="local"
                                                itemId="fasciodestino"
                                                listeners=
                                                {
                                                    {
                                                        change: function (gridView, value, columnIndex) {
                                                            Ext.ComponentQuery.query('#binarioDestino')[0].setOptions(arrayBinari.filter(x => x.ideFascio === value));
                                                        }
                                                    }}
                                            />
                                            <ComboBoxField
                                                label="Binario"
                                                width="200"
                                                labelWidth="50px"
                                                disabled={!state.edit}
                                                typeAhead
                                                options={arrayBinariDestino}
                                                margin="0 0 0 10"
                                                labelAlign="left"
                                                labelCls='LabelRiepilogo'
                                                value={row != null && row.idE_BINARIO_DES !== 0 ? row.idE_BINARIO_DES : null}
                                                queryMode="local"
                                                itemId="binarioDestino"
                                            />
                                        </FieldSet>
                                        <FieldSet hidden={isAutoprod} layout="hbox" margin="0px" defaults={{ labelAlign: "left" }} flex={1} >
                                            <TimeField
                                                label="Termine operazioni prelim."
                                                tooltip='Termine operazioni prelim.'
                                                itemId='termineOp'
                                                maxLength={5}
                                                labelAlign="left"
                                                hidden={isAutoprod}
                                                disabled={!state.edit}
                                                margin="0 0 0 10"
                                                format='H:i'
                                                width="310"
                                                labelwidth="220"
                                                picker={{
                                                    eridiem: false,
                                                    alignPMInside: true
                                                }}
                                                value={row != null ? row.toP_ORA : null}
                                            />
                                            <Container margin="0 0 0 10">
                                                <NumberField hidden={isAutoprod} disabled={!state.edit} labelAlign="left" width="120px" itemId='offsetermineOp' label="Offset" labelwidth={50} value={row != null ? row.toP_OFF_SET : 0} />
                                            </Container>
                                        </FieldSet>
                                        <FieldSet hidden={isAutoprod} layout="hbox" margin="0px" defaults={{ labelAlign: "left" }} flex={1}>
                                            <TimeField
                                                label="Trasf.materiale su bin.partenza"
                                                tooltip='Trasf.materiale su bin.partenza'
                                                labelAlign="left"
                                                disabled={!state.edit}
                                                hidden={isAutoprod}
                                                maxLength={5}
                                                margin="0 0 0 10"
                                                format='H:i'
                                                width="310"
                                                labelwidth="220"
                                                itemId='trasMater'
                                                picker={{
                                                    meridiem: false,
                                                    alignPMInside: true
                                                }}
                                                value={row != null ? row.tmP_ORA : null}
                                            />
                                            <Container margin="0 0 0 10">
                                                <NumberField hidden={isAutoprod} disabled={!state.edit} width="120px" labelAlign="left" itemId='offsetTrasMat' label="Offset" labelwidth={50} value={row != null ? row.tmP_OFF_SET : 0} />
                                            </Container>
                                        </FieldSet>
                                        <FieldSet hidden={isAutoprod} layout="hbox" margin="0px" defaults={{ labelAlign: "left" }} flex={1} >
                                            <TimeField
                                                label="Aggancio/sgancio con recupero loc."
                                                tooltip='Aggancio/sgancio con recupero loc.'
                                                labelAlign="left"
                                                hidden={isAutoprod}
                                                format='H:i'
                                                disabled={!state.edit}
                                                maxLength={5}
                                                itemId='aggsga'
                                                margin="0 0 0 10"
                                                width="310"
                                                labelwidth="220"
                                                picker={{
                                                    meridiem: false,
                                                    alignPMInside: true
                                                }}
                                                value={row != null ? row.asL_ORA : null}
                                            />
                                            <Container margin="0 0 0 10">
                                                <NumberField hidden={isAutoprod} labelAlign="left" disabled={!state.edit} width="120px" itemId='offsetAggSga' label="Offset" labelwidth={50} value={row != null ? row.asL_OFF_SET : 0} />
                                            </Container>
                                        </FieldSet>
                                        <FieldSet hidden={isAutoprod} layout="hbox" margin="0px" defaults={{ labelAlign: "left" }} flex={1}>
                                            <TimeField
                                                label="Applicazione/rimozione fanali di coda"
                                                tooltip='Applicazione/rimozione fanali di coda'
                                                hidden={isAutoprod}
                                                labelAlign="left"
                                                disabled={!state.edit}
                                                maxLength={5}
                                                margin="0 0 0 10"
                                                itemId='applicrim'
                                                format='H:i'
                                                width="310"
                                                labelwidth="220"
                                                picker={{
                                                    meridiem: false,
                                                    alignPMInside: true
                                                }}
                                                value={row != null ? row.arF_ORA : null}
                                            />
                                            <Container margin="0 0 0 10">
                                                <NumberField hidden={isAutoprod} disabled={!props.edit} width="120px" labelAlign="left" itemId='offsetApplicrim' label="Offset" labelwidth={50} value={row != null ? row.arF_OFF_SET : 0} />
                                            </Container>
                                        </FieldSet>
                                    </Panel>
                                </FieldSet>

                                <FieldSet layout="vbox" margin="0px" defaults={{ labelAlign: "left" }} >
                                    <TextAreaField margin="0 0 0 10" disabled={!state.edit} itemId='note' label="Note" value={row != null ? row.note : null} flex={1} />
                                </FieldSet>

                                <FieldSet layout="vbox" margin="0px" defaults={{ labelAlign: "left" }} >
                                    <NumberField disabled={!state.edit} itemId='massa' label="Massa" value={row != null ? row.massa : null} />
                                    <NumberField disabled={!state.edit} itemId='lunghezza' label="Lunghezza" value={row != null ? row.lunghezza : null} />
                                </FieldSet>

                                <FieldSet layout="vbox" margin="0px" >
                                    <Panel layout="hbox" bodyStyle={bkColor}>
                                        <CheckBoxField
                                            itemId='checkMP'
                                            disabled={!state.edit}
                                            label="MP" labelWidth="30"
                                            checked={row != null ? (row.mp === 0 ? false : true) : false}
                                            listeners={
                                                {
                                                    change: function (checkbox, checked) {
                                                        if (checked && state.edit)
                                                            Ext.ComponentQuery.query('#notaMP')[0].enable();
                                                        else
                                                            Ext.ComponentQuery.query('#notaMP')[0].disable();
                                                    }
                                                }}
                                        />
                                        <TextField disabled={!state.edit} itemId='notaMP' label="Note MP" labelAlign="left" margin="0 0 0 190" flex={1} value={row != null ? row.mP_NOTE : null} />
                                    </Panel>
                                    <Panel layout="hbox" bodyStyle={bkColor}>
                                        <CheckBoxField
                                            label="TE"
                                            disabled={!state.edit}
                                            itemId='checkTE'
                                            labelWidth="30"
                                            margin="0 25 0 0"
                                            checked={row != null ? (row.te === 0 ? false : true) : false}
                                            listeners={
                                                {
                                                    change: function (checkbox, checked) {
                                                        if (checked && state.edit)
                                                            Ext.ComponentQuery.query('#noteTE')[0].enable();
                                                        else
                                                            Ext.ComponentQuery.query('#noteTE')[0].disable();
                                                    }
                                                }}
                                        />
                                        <CheckBoxField
                                            label="TES"
                                            disabled={!state.edit}
                                            labelWidth="35"
                                            itemId='checkTS'
                                            margin="0 25 0 0"
                                            checked={row != null ? (row.tes === 0 ? false : true) : false}
                                            listeners={
                                                {
                                                    change: function (checkbox, checked) {
                                                        if (checked && state.edit)
                                                            Ext.ComponentQuery.query('#noteTE')[0].enable();
                                                        else
                                                            Ext.ComponentQuery.query('#noteTE')[0].disable();
                                                    }
                                                }}
                                        />
                                        <CheckBoxField
                                            label="TEM"
                                            disabled={!state.edit}
                                            labelWidth="35"
                                            itemId='checkTP'
                                            margin="0 20 0 0"
                                            checked={row != null ? (row.tep === 0 ? false : true) : false}
                                            listeners={
                                                {
                                                    change: function (checkbox, checked) {
                                                        if (checked && props.edit)
                                                            Ext.ComponentQuery.query('#noteTE')[0].enable();
                                                        else
                                                            Ext.ComponentQuery.query('#noteTE')[0].disable();
                                                    }
                                                }}
                                        />
                                        <TextField disabled={!state.edit} itemId='noteTE' label="Note TE" labelAlign="left" flex={1} value={row != null ? row.tE_NOTE : null} />
                                    </Panel>
                                    <Panel layout="hbox" bodyStyle={bkColor}>
                                        <CheckBoxField
                                            label="TC"
                                            disabled={!state.edit}
                                            itemId='checkTC'
                                            labelWidth="30"
                                            checked={row != null ? (row.tc === 0 ? false : true) : false}
                                            listeners={
                                                {
                                                    change: function (checkbox, checked) {
                                                        if (checked && state.edit)
                                                            Ext.ComponentQuery.query('#noteTC')[0].enable();
                                                        else
                                                            Ext.ComponentQuery.query('#noteTC')[0].disable();
                                                    }
                                                }}
                                        />
                                        <TextField disabled={!state.edit} label="Note TC" itemId='noteTC' labelAlign="left" margin="0 0 0 190" flex={1} value={row != null ? row.tC_NOTE : null} />
                                    </Panel>
                                </FieldSet>
                                <Button margin="5 0 5 0" cls="marginButton appButtonRTO" text="Conferma" hidden={!state.edit} handler={handleSubmit} />
                                <Button margin="5 0 5 0" cls="marginButton appButtonPO" text={state.edit === true ? "Annulla" : "Chiudi dettaglio manovra"} handler={handleCancel} />
                            </Formpanel>
                        </Panel>
                    </Container>
                    {isCalendarioVisible && (
                        <Panel title={titolo} itemId="panelCalendarioPerManII" closable={true} listeners=
                            {
                                {
                                    close: function () {
                                        setIsCalendarioVisible(false);
                                        setStateLoadMask({ hidden: true, message: "Attendere salvataggio in corso...", ricarica: false });
                                    }
                                }} tools={[
                                    {
                                        iconCls: 'x-fa x-fa fa-plus', tooltip: 'Nuovo', hidden: !props.inEdit, handler: onNuovaPeriodicitaIIManClick.bind(this)
                                    }
                                    /* { type: 'menu', handler: toolHandler }*/
                                ]}>
                            <Grid
                                itemId="gridPeriodicitaIIManovre"
                                minHeight={100}
                                maxHeight={100}
                                markDirty={true}
                                flex={1}
                                scrollable
                                selectable={{ checkbox: false, mode: 'single' }}
                                store={storePeriodicitaIIManovre}
                                plugins={{
                                    gridfilters: {}, rowedit: { autoConfirm: false, invalidToastMessage: "Verificare i campi inseriti" },
                                }}
                                itemConfig={{
                                    viewModel: true
                                }}
                                listeners={{
                                    beforeedit: function (editor, context, eOpts) {
                                        if (!props.inEdit)
                                            return false;
                                    },
                                    edit: function (grid, location) {
                                        setStateLoadMask({ hidden: false, message: "Salvataggio in corso...", ricarica: false });
                                        var periodicitaByDB = null;
                                        Ext.Ajax.request({
                                            url: 'Rto/GetImpegnoImpianto',
                                            method: 'GET',
                                            params: {
                                                ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                                            },
                                            callback: function (resp, success, operation) {
                                                const idPeriodicita = location.record.data.idePerImpegnoImpianto;
                                                periodicitaByDB = Ext.decode(operation.responseText);
                                                var idPeriodicitaNew = null;

                                                if (periodicitaByDB.periodicitaImpegnoImpiantoManovre.find(per => per.idePerImpegnoImpianto === idPeriodicita) === undefined) {
                                                    var periodicitaNewObject = location.record.data;
                                                    periodicitaNewObject.ideLoc = localitaSelezionata;
                                                    periodicitaNewObject.ideParent = manovraSelezionata;
                                                    periodicitaNewObject.datIniPer.setHours(periodicitaNewObject.datIniPer.getHours() + 4);
                                                    periodicitaNewObject.datFinPer.setHours(periodicitaNewObject.datFinPer.getHours() + 4);
                                                    periodicitaNewObject.flgSmr = true;
                                                    periodicitaNewObject.flgLnd = false;
                                                    periodicitaNewObject.flgMrt = false;
                                                    periodicitaNewObject.flgMrc = false;
                                                    periodicitaNewObject.flgGvd = false;
                                                    periodicitaNewObject.flgVnr = false;
                                                    periodicitaNewObject.flgSbt = false;
                                                    periodicitaNewObject.flgDmn = false;
                                                    periodicitaNewObject.flgLav = false;
                                                    periodicitaNewObject.flgFes = false;
                                                    periodicitaNewObject.flgPtf = false;
                                                    periodicitaNewObject.flgPof = false;
                                                    periodicitaNewObject.flgSegPof = false;
                                                    var periodicitaNewJson = Ext.encode(periodicitaNewObject);
                                                    Ext.Ajax.request({
                                                        url: 'Rto/InserisciPeriodicitaManovreImpegnoImpianto',
                                                        method: 'POST',
                                                        params: {
                                                            ideUte: user.ideUte,
                                                            dati: periodicitaNewJson
                                                        },
                                                        callback: function (records, success, operation) {
                                                            if (success) {
                                                                var returnObj = Ext.decode(operation.responseText);
                                                                //modifica gestione errore
                                                                if (returnObj.resp == "KO") {
                                                                    setStateLoadMask({ hidden: true, message: "Eliminazione servizio in corso...", ricarica: false });
                                                                    Ext.Msg.alert('Inserimento periodicita manovra fallito', returnObj.msgErr)
                                                                }
                                                                else {
                                                                    idPeriodicitaNew = returnObj.idPerMan;
                                                                    setStateLoadMask({ hidden: false, message: "Attendere...", ricarica: false });
                                                                    Ext.Ajax.request({
                                                                        url: 'Rto/GetImpegnoImpianto',
                                                                        method: 'GET',
                                                                        params: {
                                                                            ideRTO: rtoSchede.data.items[0].data.rto.ideRTO
                                                                        },
                                                                        callback: function (resp, success, operation) {
                                                                            const info = new Object();
                                                                            info.record = new Object();
                                                                            info.record.data = new Object();
                                                                            const idPeriodicitaNewApp = idPeriodicitaNew;
                                                                            periodicitaByDB = Ext.decode(operation.responseText);
                                                                            info.record.data = periodicitaByDB.periodicitaImpegnoImpiantoManovre.find(per => per.idePerImpegnoImpianto === idPeriodicitaNewApp);
                                                                            setStateLoadMask({ hidden: true, message: "Attendere...", ricarica: false });
                                                                            modificaGiorniCircolazione(null, info);
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                            else {
                                                                Ext.Msg.alert('Inserimento periodicita manovra fallito', 'Inserimento periodicita manovra fallito');
                                                                setStateLoadMask({ hidden: true, message: "Eliminazione servizio in corso...", ricarica: false });
                                                            }
                                                        }
                                                    })
                                                }
                                                else {

                                                    var periodicitaObject = location.record.data;
                                                    periodicitaObject.datIniPer = new Date(periodicitaObject.datIniPer);
                                                    periodicitaObject.datFinPer = new Date(periodicitaObject.datFinPer);
                                                    periodicitaObject.datIniPer.setHours(periodicitaObject.datIniPer.getHours() + 4);
                                                    periodicitaObject.datFinPer.setHours(periodicitaObject.datFinPer.getHours() + 4);
                                                    periodicitaObject.ideLoc = localitaSelezionata;
                                                    periodicitaObject.ideParent = manovraSelezionata;
                                                    periodicitaObject.flgSmr = location.record.data.flgSmr;
                                                    periodicitaObject.flgLnd = location.record.data.flgLnd;
                                                    periodicitaObject.flgMrt = location.record.data.flgMrt;
                                                    periodicitaObject.flgMrc = location.record.data.flgMrc;
                                                    periodicitaObject.flgGvd = location.record.data.flgGvd;
                                                    periodicitaObject.flgVnr = location.record.data.flgVnr;
                                                    periodicitaObject.flgSbt = location.record.data.flgSbt;
                                                    periodicitaObject.flgDmn = location.record.data.flgDmn;
                                                    periodicitaObject.flgLav = location.record.data.flgLav;
                                                    periodicitaObject.flgFes = location.record.data.flgFes;
                                                    periodicitaObject.flgPtf = location.record.data.flgPtf;
                                                    periodicitaObject.flgPof = location.record.data.flgPof;
                                                    periodicitaObject.flgSegPof = location.record.data.flgSegPof;
                                                    var periodicitaJson = Ext.encode(periodicitaObject);
                                                    Ext.Ajax.request({
                                                        url: 'Rto/ModificaPeriodicitaManovreImpegnoImpianto',
                                                        method: 'POST',
                                                        params: {
                                                            ideUte: user.ideUte,
                                                            periodicitaDes: periodicitaJson
                                                        },
                                                        callback: function (records, success, operation) {
                                                            if (success) {
                                                                var returnObj = Ext.decode(operation.responseText);
                                                                //modifica gestione errore
                                                                if (returnObj.resp === "KO") {
                                                                    setStateLoadMask({ hidden: true, message: "Eliminazione servizio in corso...", ricarica: false });
                                                                    Ext.Msg.alert('Modifica periodicita manovra fallita', returnObj.msgErr);
                                                                    location.record.reject();
                                                                }
                                                                else {
                                                                    refreshGridPeriodicitaManII();
                                                                    Ext.toast({ message: 'Periodicita manovra modificata', timeout: 4000 });
                                                                    aggiornaControllo(true);
                                                                    Ext.Ajax.request({
                                                                        url: 'Rto/CheckPeriodicitaSML',
                                                                        method: 'POST',
                                                                        params: {
                                                                            idePer: manovraSelezionata,
                                                                            ideTipo: 3
                                                                        },
                                                                        callback: function (records, success, operation) {
                                                                            var returnCheck = Ext.decode(operation.responseText);
                                                                            if (returnCheck.length > 0) {
                                                                                Ext.Msg.alert('Attenzione', returnCheck);
                                                                                aggiornaStateCheck(false);
                                                                            }
                                                                            else {
                                                                                aggiornaStateCheck(true);
                                                                            }
                                                                        }
                                                                    });
                                                                }
                                                            }
                                                            else {
                                                                Ext.Msg.alert('Modifica periodicita manovra fallita', 'Modifica periodicita manovra fallita');
                                                                location.record.reject();
                                                                setStateLoadMask({ hidden: true, message: "Eliminazione servizio in corso...", ricarica: false });
                                                            }

                                                        }
                                                    });
                                                }
                                            }
                                        })

                                    },
                                    canceledit: function (editor, context) {
                                        refreshGridPeriodicitaManII();
                                    }
                                }}
                            >
                                <Column itemId='actionRTOPeriodicitaMan' editable={false} text="Azioni" width={150} hidden={!props.inEdit}
                                    cell={{

                                        tools: {

                                            aggiungiPeriodicita: {

                                                handler: onAggiungiPeriodicitaManovraIIClick.bind(this),

                                                bind: {

                                                    iconCls: "x-fa fa-plus",

                                                    tooltip: 'Aggiungi'
                                                }

                                            },

                                            modificaPeriodicita: {

                                                handler: onModificaPeriodicitaManovraIIClick.bind(this),

                                                bind: {

                                                    iconCls: "x-fa fa-edit",

                                                    tooltip: 'Modifica'
                                                }

                                            },

                                            eliminaPeriodicita: {

                                                handler: onEliminaPeriodicitaManovraIIClick.bind(this),

                                                bind: {

                                                    iconCls: "x-fa fa-trash",

                                                    tooltip: 'Elimina'
                                                }

                                            }

                                        }

                                    }}
                                />
                                <Column text="Operativita" dataIndex="ideOpv" width={150} filter='string' renderer={function (value, metaData, records, view) {
                                    if (value === 0)
                                        return "Si Effettua"
                                    else
                                        return "Non si Effettua"
                                }} editor={{
                                    xtype: 'combobox', editable: true, itemId: 'cmbOpeMan', triggerAction: 'all', mode: 'local', options:
                                        [
                                            { text: 'Si Effettua', value: 0 },
                                            { text: 'Non si Effettua', value: 1 }
                                        ]
                                    ,
                                    allowBlank: false, requiredMessage: "Questo campo non puo' essere vuoto", required: true

                                }} />
                                <Column text="Dal" width={200} type="datecolumn" formatter='date("d/m/Y")' filter='date' dataIndex="datIniPer" editor={{
                                    xtype: 'datefield', triggerAction: 'all', allowBlank: false, itemId: "dataIniManII", requiredMessage: "Questo campo non puo' essere vuoto", required: true, listeners: {
                                        change: function (filed, newValue) {
                                            if (newValue > rangeDateEnd || newValue < rangeDateStart) {
                                                Ext.ComponentQuery.query('#dataIniManII')[0].setValue(null);
                                            }
                                        }
                                    }
                                }} />
                                <Column text="Al" width={200} type="datecolumn" formatter='date("d/m/Y")' filter='date' dataIndex="datFinPer" editor={{
                                    xtype: 'datefield', triggerAction: 'all', allowBlank: false, itemId: "dataFinManII", requiredMessage: "Questo campo non puo' essere vuoto", required: true, listeners: {
                                        change: function (filed, newValue) {
                                            if (newValue > rangeDateEnd || newValue < rangeDateStart) {
                                                Ext.ComponentQuery.query('#dataFinManII')[0].setValue(null);
                                            }
                                        }
                                    }
                                }} />
                                <Column text="Giorni" editable={false} dataIndex="desPer" filter='string' flex={1} cell={{

                                    tools: {

                                        modificaMerci: {

                                            handler: modificaGiorniCircolazione.bind(this),

                                            bind: {

                                                iconCls: "x-fa fa-edit buttonMerciTool",

                                                tooltip: 'Modifica Giorni Circolazione',

                                                hidden: !props.inEdit
                                            }
                                        }

                                    }

                                }} />

                            </Grid>

                            {calendars != null && renderListOfCalendar(calendars)}
                        </Panel>)}
                </Container>
            </Panel>
        </Container>
    );
}

export default RtoIIManovre;